
export default {
    translation: {
        title: 'Vancare App',
        sidebar:{
            dashboard: 'Dashboard',
            my_device: 'My Device',
            interesting_promo: 'Interesting Promo',
            news: 'News',
            customer_service: 'Customer Service',
            service_center: 'Service Center',
            settings: 'Settings',
            notifications:"Notification",
            faq:"FAQ",
        },
        empty:"Empty",
        serial_number: "Serial Number",
        apps_update :"Update App",
        update: "Update",
        register_your_device:"Register your device to activate the warranty",
        active_warranty: "Active Warranty",
        until: "Until",
        system_information:"System Information",
        processor:"Processor",
        memory:"Memory",
        storage:"Storage",
        screen:"Screen",
        camera:"Camera",
        material:"Material",
        system_operation:"System Operation",
        source: "Source",
        address: "Address",
        outlet_detail: "Outlet Detail",
        contact_name: "Contact Name",
        contact_email: "Contact Email",
        contact_number: "Contact Number",
        hello:"Hello",
        send:"Send",
        welcome:"Welcome!",
        send_request:"Send Request",
        request_sended:"Request Sended",
        back:"Back",
        news_detail:"News Detail",
        faq_detail:"Detail FAQ",
        registered:"Registered",
        expired_at:"Expired At",
        chose_model:"Chose Model",
        chose_system_name:"Chose Device Type",
        register_device:"Register Device",
        add_device:"Add Device",
        auth:{
            add:"Add",
            agree:"Agree",
            disagree:"Disagree",
            login:"Login",
            register:"Register",
            sign_up:"Sign Up",
            remember_me:"Remember Me",
            forgot_password:"Forgot Password?",
            message_verification:"We have sent you a verification code through your email",
            message_name_diff:"Email registered before, but full name is different from the last time email registered, please confirm which name you will use",
            change_email:"Change Email",
            full_name:"Full Name",
            new_full_name:"New Full Name",
            phone:"Phone Number",
            placeholder_phone:"Phone Number start with 08xxxxxxxx",
            new_email:"New Email",
            verify:'Verify',
            resend:'Resend',
            current_password:"Current Password",
            new_password:"New Password",
            password_mismatch:"Password Mismatch",
            apply:'Apply',
            password_is_too_short:"Password is too short (min 8 character)",
            cancel:'Cancel',
            serial_number_required:"Serial Number Required",
            device_name_required:"Device Name Required",
            model_required:"Model Required",
            system_name_required:"Device Type Required",
            password_required:"Password Required",
            phone_required:"Phone Number Required",
            confirm_password_required:"Confirm Password Required",
            choose_sn:"Please confirm which device you are using",
            serial_number_owned_by_another_user:"Serial Number owned by another user",
            serial_number_not_found:"Serial Number not found",
            device_registered:"Device Registered",
            device_already_registered:"Device Already Registered",
            email_already_registered:"Email Already Registered",
            user:{
                not_found:"User not Found",
                not_verified:"User Hasn't verify token"
            },
            token:{
                verified:"Account Verified",
                invalid:"Token Invalid",
            },
            alert:{
                verify_success_but_name:"This email has registered before, but full name different from the last time",
                verify_success:"Verification Success",
                change_email_success:"Change Email Success",
                email_format_invalid:'Email format invalid',
                email_format_valid:'Email Format Valid',
                register_success:"Register Success",
                account_exists:"Account Exists",
                token_resended:"Token Resended",
                change_name_success:"Change Name Success",
                change_password_success:"Change Password Success",
                serial_number_already_exist:"Serial Number already registered",
                device_hasnot_registered:"Device Has not registered, please register",
                serial_number_not_for_this_user:"Device Serial Number are not registered for this email, Send request for new owner",
                agree_terms:"Please agree to the terms and conditions",
                terms_agreed:"Terms and conditions agreed",
                device_available_unregistered:"Device Available but unregistered to any account",
                device_unavailable:"Device unavailable"
            },
            terms_and_conditions:"Terms and Conditions",
            chose_how_to_register:"Choose how to register your device",
            explanation: "Explanation",
            explanation_text: "To ensure the authenticity and security of the product, we require your serial number for the registration process. This serial number is unique and only given to legitimate and authentic products.",
            reason_of_use: "Reason for Using Serial Number",
            reason_of_use_1: "Authenticity Verification: The serial number helps us verify that your product is genuine and not counterfeit.",
            reason_of_use_2: "Security: By using the serial number, we can ensure that your product is protected from unauthorized access.",
            reason_of_use_3: "Warranty Registration: The serial number is required to register your product warranty.",
            reason_of_use_4: "Technical Support: The serial number helps us provide more effective technical support.",
            how_to_protect: "How We Protect Your Information",
            how_to_protect_1: "Confidentiality: We promise to keep your information confidential.",
            how_to_protect_2: "Security: We use the latest security technology to protect your information.",
            how_to_protect_3: "Regulatory Compliance: We ensure that our use of the serial number complies with applicable regulations and security standards.",
            agreement: "Agreement",
            agreement_text: "By clicking 'Agree', you consent to:",
            agreement_1: "Provide your serial number for the registration process.",
            agreement_2: "Understand and accept our privacy and security policies.",
            sn_info: "If you have any questions or concerns, please contact us through",
            or: "or",
        },
        change_email:"Change Email",
        change_password:"Change Password",
        change_profile:"Change Profile",
        settings:{
            change_profile:"Change Profile",
            change_password:"Change Password",
        },
        network_error:"Somethings wrong with the internet connection (No Internet)",
        warranty_expired:"Warranty Expired",
        check_status_warranty:"Check Status Warranty",
        input_sn:"Input Serial Number",
        how_to_identify_sn:"How to identify Serial Number",
        aggrement_text:"I agree to provide my product serial number to ADVAN to ask about the warranty period for my product, and also agree to",
        privacy_policy:"Privacy Policy",
        are_you_sure:"Are you sure",
        using_sn:"You want to activate the device warranty on this account?",
        yes:"Yes",
        no:"No",
        device_on_service:"Device on Service",
        link_product_not_found:"Link Product not found",
        device_neverbeen_onservice:"Device never on service",
        on_outlet:"Di Outlet",
        notes:"Catatan"
    }
}

