import React from "react";

const dayLeftInAMonth=(date)=>{
    let currentDate = new Date(date)
    var currentYear = currentDate.getFullYear();
    var currentMonth = currentDate.getMonth();

    var currentMonthLastDate = (new Date(currentYear, currentMonth + 1, 0)).getDate() + 1;

    var daysLeftInMonth = currentMonthLastDate - currentDate.getDate();
    console.log(currentMonthLastDate,"currentMonthLastDate")
    return daysLeftInMonth
}

function treatAsUTC(date) {
    var result = new Date(date);
    result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    return result;
}

function daysBetween(startDate, endDate) {
    var millisecondsPerDay = 24 * 60 * 60 * 1000;
    return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
}

const RunCircular= async (data)=>{

    let newDate = new Date(data?.register_date)
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1; // real use
    let year = newDate.getFullYear();

    let newDateExpired = new Date(data?.expired_date)
    let date_expired = newDateExpired.getDate();
    let month_expired = newDateExpired.getMonth() + 1; // real use
    let year_expired = newDateExpired.getFullYear();
    let expired_date = year_expired +"-"+ month_expired +"-"+ date_expired // real use

    var warranty_month = data?.warranty_month
    // console.log(warranty_month,"warranty_month")
    // console.log(expired_date,"expired_date")
    var limit_day = 0;
    var month_count = month
    var year_count = year
    var date_count = date
    var call_month = ""
    for(var i = 0;i<warranty_month;i++){
        call_month = year_count +"-"+ month_count +"-"+date_count
        // console.log(call_month,"call_month")
        var daysLeftInMonth = i == 0 ? dayLeftInAMonth(call_month)-1 : dayLeftInAMonth(call_month)
        // console.log(daysLeftInMonth,"daysLeftInMonth");
        limit_day += daysLeftInMonth
        date_count = 1
        if(month_count == 12){
            month_count = 1
            year_count += 1
        }else{
            month_count += 1
        }
        // console.log(limit_day,"limit day")
        if(warranty_month-1 == i && expired_date != call_month){
            // console.log('last months',expired_date)
            // console.log(date,"daysLeftInMonth");
            limit_day += date
            // console.log(limit_day,"limit day")
        }
    }
    let newDateToday = new Date()
    let today_date = newDateToday.getDate();
    let today_month = newDateToday.getMonth() + 1; // real use
    let today_year = newDateToday.getFullYear();
    let today = today_year +"-"+ today_month +"-"+ today_date // real use
    // let today = '2024-11-05' // simulation
    var day_left = daysBetween(today, data?.expired_date)
    var day_percentage = (parseInt(day_left)/limit_day)*100;
    console.log(parseInt(day_left),"day_left")
    console.log(day_percentage,"day_percentage")    
    return day_percentage
}

export default RunCircular