import React, { useState,useEffect } from 'react';
import './App.css';
import { RouterProvider, useLocation, useNavigate } from "react-router-dom";
import routes from "./routes/web";
import { getCustomer, getCustomerDevice, getToken, getUser, removeSession, setSession } from './helpers/auth-helper';
import toast from 'react-hot-toast';
import axios from 'axios';
import { t } from 'i18next';
import { initializeAppNotification, requestForToken } from './components/firebase';
import Notification from './components/NotificationFirebase';
import { PageProvider } from './helpers/page-context';
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {

  const [splash,setSplash]= useState(false)

  const getCurrentDate=(separator='')=>{

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    
    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
  }

  useOnceCall(() => {
    var call = checkFirstLoad();
    // console.log(call,"call")
    if(call === 'load_first'){
      localStorage.setItem('register_date',getCurrentDate('-'))
      auth()
      setSplash(true)
      setTimeout(() => {
        setSplash(false)
      },2500)
      sessionStorage.setItem('first_load','yes')
    }
  })

  const checkFirstLoad=()=>{
    var first_load = sessionStorage.getItem('first_load')
    if(first_load && first_load === 'yes'){
      return 'load_not_first'
    }else{
      return 'load_first'
    }
  }

  async function auth() {
      const user = await getUser()
      const token = getToken()
  
      if(user){
        requestForToken(user.id)
      }else{
        initializeAppNotification()
      }

      if (user === null && token !== null) {
          await axios.post(`${process.env.REACT_APP_API}/token/verify`, {
              token: token
          }).then((response) => {
              setSession(response.data.data.token, response.data.data.user,response.data.data.customer)
              toast.success(t(response.data.message))
              // console.log(response.data.message)
          }).catch((error) => {
              removeSession()
              toast.error(t(error.response.data.message))
              // console.log(error.response.data.message)
          })
      }
  }
  
  if(splash){
    return (
      <div className="App">
        <header className="App-header">
          <img src={process.env.PUBLIC_URL + '/logo192.png'} className="App-logo" alt="logo" />
        </header>
        <body className='App-body'>
          <label className='color-2 font-extrabold text-2xl'>
              Vancare
          </label>
          <label className='color-1 text-sm'>
            Version {process.env.REACT_APP_VERSION}
          </label>
        </body>
      </div>
    );
  }else{
    return (
      <div className="App">
        <PageProvider>
          <Notification/>
          <RouterProvider router={routes} fallbackElement={<div>Loading</div>} />
        </PageProvider>
      </div>
    );
  }
}

function useOnceCall(cb, condition = true) {
  const isCalledRef = React.useRef(false);

  useEffect(() => {
    if (condition && !isCalledRef.current) {
      isCalledRef.current = true;
      cb();
    }
  }, [cb, condition]);
}

export default App;
