
import { Navigate, useLocation } from 'react-router-dom';
import { getUser } from "../../helpers/auth-helper";

const GuestRoute = ({ children }) => {
	const user = getUser();
	const location = useLocation();
	const { pathname } = location;

	if(user && !pathname.includes('/check-sn')){
		return <Navigate to="/dashboard" replace />;
	}

	return children
};

export default GuestRoute;
