import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import moment from "moment/moment";
import ReactPlayer from 'react-player';
import parse from 'html-react-parser';
import { getData } from "../helpers/api-helper";
import useCMSRepositories from "../repositories/cms";
import { usePage } from "../helpers/page-context";

export default function NewsDetails(props) {
    const { data: side_data } = useCMSRepositories(1, 15);
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const param = useParams();
    const [data, set_data] = useState({});

    const { set_notifications } = usePage();

    const readNotification = () => {
        var url = process.env.REACT_APP_WEB_URL + location.pathname;
        const storage_notification = localStorage.getItem('notifications');
        var old_notification = JSON.parse(storage_notification);
        var deleted = old_notification.filter(item => { return !(item.fcmOptions.link == url) });
        localStorage.setItem('notifications', JSON.stringify(deleted));
        set_notifications(deleted);
    };

    useEffect(() => {
        getDataDetail();
    }, [param?.id]);

    const getDataDetail = async () => {
        try {
            const response = await getData(`${process.env.REACT_APP_API}/cms/show_detail`, { id: param?.id });

            if (response?.data) {
                set_data(response?.data?.data);
            } else {
                toast.error(t('error_loading_data'));
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            toast.error(t('error_loading_data'));
        }
    };

    function stripHtml(html) {
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
    }

    const redirect = (row) => {
        navigate("/news/detail/" + row.id, { state: { row } });
    };

    return (
        <div className="dashboard-page" style={{ height: 'auto' }}>
            <div className="flex font-bold text-lg items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-200 group">
                <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.75 1.99899H6.75C4.65 1.99899 3.6 1.999 2.798 2.409C2.09253 2.76872 1.51859 3.34196 1.158 4.047C0.750005 4.847 0.75 5.89999 0.75 7.99899V17.25C0.75 19.35 0.750005 20.4 1.158 21.203C1.51859 21.9083 2.09249 22.4819 2.798 22.842C3.598 23.25 4.65 23.25 6.75 23.25H16.001C18.101 23.25 19.151 23.25 19.953 22.842C20.6579 22.4817 21.2311 21.9081 21.591 21.203C22.001 20.403 22.001 19.35 22.001 17.25V13.25M22.152 1.85001C22.6772 2.37433 23.0352 3.04267 23.1804 3.77048C23.3257 4.49828 23.2518 5.25282 22.9681 5.93861C22.6844 6.62441 22.2036 7.21062 21.5866 7.62308C20.9696 8.03554 20.2442 8.25571 19.502 8.25571C18.7598 8.25571 18.0344 8.03554 17.4174 7.62308C16.8004 7.21062 16.3196 6.62441 16.0359 5.93861C15.7522 5.25282 15.6783 4.49828 15.8236 3.77048C15.9688 3.04267 16.3268 2.37433 16.852 1.85001C17.5553 1.14885 18.5079 0.755127 19.501 0.755127C20.4941 0.755127 21.4467 1.14885 22.15 1.85001H22.152Z" stroke="#1D1B20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span className="ms-3">{t('news_detail')}</span>
            </div>
            <div className="flex justify-evenly">
                <div style={{ width: "60%" }}>
                    <div className={"p-3 text-left rounded bg-white mt-2 mr-2 cursor-pointer"}>
                        <h3 className="font-bold line-clamp-2 text-center text-2xl">{data?.title}</h3>
                        {
                            data?.type == 'video' ?
                                <ReactPlayer url={data?.source} width={'100%'} />
                                :
                                <img src={data?.thumbnail} className="py-3 items-center m-auto" style={{ width: "80%" }} />
                        }
                    </div>
                    <div className="my-2">
                        <a href={data?.source} className="text-md items-center text-blue-600 rounded-lg dark:text-blue-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                            {t('source')}
                        </a>
                    </div>
                    <div className={"text-left rounded bg-transparent mr-2 cursor-pointer"}>
                        <p className="my-5 text-md">{moment(data?.created_at).format('LLLL')}</p>
                        <p className="text-base" dangerouslySetInnerHTML={{ __html: data.description }} />
                    </div>
                </div>
                <div style={{ width: "30%" }}>
                    {side_data?.data?.length > 0 && side_data?.data?.filter(item => item.id != data?.id).map((row) => (
                        <div className={"p-3 text-left rounded bg-white mt-2 mr-2 cursor-pointer"} onClick={() => redirect(row)}>
                            <div className="">
                                <a href={row.source} className="text-xs items-center text-blue-600 rounded-lg dark:text-blue-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                                    {t('source')}
                                </a>
                                <h3 className="font-bold text-xl line-clamp-2">{row.title}</h3>
                                <p className="text-xs font-semibold line-clamp-3">{stripHtml(row.description)}</p>
                                <p className="mt-2 text-xs text-gray-500">{moment(row.created_at).format('LLLL')}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}