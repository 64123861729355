import React, { useState } from 'react';
import { postData } from '../helpers/api-helper';
import { setSession } from '../helpers/auth-helper';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { requestForToken } from '../components/firebase';
import { Modal, ModalBody } from 'reactstrap';

export default function Login(props){

    const { t } = useTranslation();
    const [email,set_email] = useState("")
    const [password,set_password] = useState("")
    const [remember_me,set_remember_me] = useState(0)
    
    const [loading,set_loading] = useState(false)
    const [change_owner,set_change_owner] = useState(false)
    const [chose_sn,set_chose_sn] = useState(false)
    const [ask_user_consent,set_ask_user_consent] = useState(false)
    const [customer_id,set_customer_id] = useState("")

    const [owner,set_owner] = useState({})
    const [serial_numbers,set_serial_numbers] = useState([])

    const [user,set_user] = useState({})
    const [customer,set_customer] = useState({})
    const [token,set_token] = useState("")

    const navigate = useNavigate()

    const Login=()=>{
        var serial_number = localStorage.getItem('serial_number');
        // if(serial_number){
            var params = {email:email,password:password,serial_number,remember_me:remember_me ? 1 : 0}
            set_loading(true)
            postData(`${process.env.REACT_APP_API}/login`,params).then((response) => {
                if(response.status === 200){
                    setSession(response.data.data.token,response.data.data.user,response.data.data.customer)
                    requestForToken(response.data.data.user.id)
                    if(response.data.data.customer.user_consent == 0 || response.data.data.customer.user_consent == 2){
                        toast.success('Please agree to the terms and conditions')
                        set_ask_user_consent(true)
                        set_customer_id(response.data.data.customer.id)
                    }else if(response.data.data.customer.user_consent == 1){
                        toast.success('Login Success')
                        navigate('/dashboard')
                    }
                }else if(response?.data.message == "auth.user.not_verified"){
                    console.log(response.data)
                    navigate('/verification',{ state: {customer_code:response.data.customer.customer_code,full_name:response.data.customer.full_name,email:email}})
                }else if(response?.data.message == "auth.alert.serial_number_not_for_this_user"){
                    console.log(response.data)
                    set_change_owner(true)
                    set_owner(response.data.customer)
                }else if(response?.data.message == "auth.choose_sn"){
                    if(response.data.data.devices.length == 1){
                        // localStorage.setItem('serial_number',response.data.data.devices[0].serial_number)
                        setTimeout(() => {
                            setSession(response.data.data.token,response.data.data.user,response.data.data.customer)
                            requestForToken(response.data.data.user.id)
                            toast.success('Login Success')
                            navigate('/dashboard')
                        }, 500);
                    }else{
                        set_user(response.data.data.user)
                        set_customer(response.data.data.customer)
                        set_token(response.data.data.token)
                        set_serial_numbers(response.data.data.devices)
                        set_chose_sn(true)
                    }
                }
                set_loading(false)
            }).catch((response)=>{
                console.log(response,"Tai")
                set_loading(false)
                toast.error('Login Error')
            })
        // }else{
        //     toast.error(t("auth.alert.device_hasnot_registered"))
        // }
    }

    const requestChangeOwner=()=>{
        var serial_number = localStorage.getItem('serial_number');
        var params = {owner_email:owner.email,email:email,serial_number}
        set_loading(true)
        postData(`${process.env.REACT_APP_API}/change-sn-owner`,params).then((response) => {
            if(response.status === 200){
                toast.success(t('request_sended'))
                set_change_owner(false)
                set_owner({})
            }
            set_loading(false)
        }).catch((response)=>{
            set_loading(false)
            toast.error('Login Error')
        })
    }

    const choseSN=(serial_number)=>{
        localStorage.setItem('serial_number',serial_number)
        setSession(token,user,customer)
        requestForToken(user.id)
        toast.success('Login Success')
        setTimeout(() => {
            navigate('/dashboard')
        }, 500);
    }

    const submitTerms=(status)=>{
        var params = {user_consent:status,customer_id:customer_id}
        set_loading(true)
        postData(`${process.env.REACT_APP_API}/user-consent`,params).then((response) => {
            if(response.status === 200){
                set_ask_user_consent(false)
                if(status == 2){
                    toast.error('Terms and conditions not agreed')
                }else{
                    toast.success('Terms and conditions agreed')
                    navigate('/dashboard')
                }
            }
            set_loading(false)
        }).catch((response)=>{
            set_loading(false)
            toast.error('Login Error')
        })
    }

    const formPage=()=>{
        if(chose_sn){
            return(
                <div className="py-16 px-10">
                    <header className="pl-10 flex">
                        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                        <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>Vancare App</h2>
                    </header>
                    <div className='px-8 py-3'>
                        <h5 className='text-left text-5xl'>{change_owner ? '' : t('hello')}</h5>
                        <h5 className='text-left text-2xl font-bold'>{t('auth.choose_sn')}</h5>
                    </div>
                    <div className="mt-1 grid px-8">
                        {
                            serial_numbers && serial_numbers.map((data,index) =>
                                <div className="my-3" key={2} onClick={() => choseSN(data.serial_number)}>
                                    <button className='bg-white border-2 border-solid rounded-md p-6 w-96' style={{borderColor:"#EB008B"}}>
                                        <p style={{color:"#EB008B"}}>
                                            {data.serial_number}
                                        </p>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            )
        }else if(ask_user_consent){
            return(
                <div className="py-8 px-10">
                    <header className="flex">
                        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                        <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>{t('auth.terms_and_conditions')}</h2>
                    </header>
                    <div className='text-left'>
                    <h1 className="text-xl"><b>{t('auth.explanation')}</b></h1>
                    <p>
                        {t('auth.explanation_text')}
                    </p>
                    &nbsp;
                        <ul>
                            <b>
                            {t('auth.reason_of_use')}
                            </b>
                            <li>
                            1. {t('auth.reason_of_use_1')}
                            </li>
                            <li>
                            2. {t('auth.reason_of_use_2')}
                            </li>
                            <li>
                            3. {t('auth.reason_of_use_3')}
                            </li>
                            <li>
                            4. {t('auth.reason_of_use_4')}
                            </li>
                        </ul>
                        &nbsp;
                        <ul><b>{t('auth.how_to_protect')}</b>
                            <li>1. {t('auth.how_to_protect_1')}</li>
                            <li>2. {t('auth.how_to_protect_2')}</li>
                            <li>3. {t('auth.how_to_protect_3')}</li>
                        </ul>
                        &nbsp;
                        <ul><b>{t('auth.agreement')}</b>
                            <p>{t('auth.agreement_text')}</p>

                        <li>1. {t('auth.agreement_1')}</li>
                        <li>2. {t('auth.agreement_2')}</li>
                        </ul>
                        <p>{t('auth.sn_info')}  <a>xxxxxxx@gmail.com</a> {t('auth.or')} +62 xxxxxxxxx.</p>
                        
                        { loading ? 
                        <div role="status" className="mt-2 flex justify-center">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div> : 
                        <div className="mt-2 flex">
                            <button type='button' className='btn-register' onClick={(e) => submitTerms(1)}>{t('auth.agree')}</button>&nbsp; &nbsp; &nbsp; &nbsp;
                            <button type='button' className='btn-resend' onClick={(e) => submitTerms(2)}>{t('auth.disagree')}</button>
                        </div>
                        }
                    </div>
                </div>
            )
        }else{
            return(
                <div className="py-16 px-10">
                    <header className="pl-10 flex">
                        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                        <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>Vancare App</h2>
                    </header>
                    <div className='px-8 py-3'>
                        <h5 className='text-left text-5xl'>{change_owner ? '' : t('hello')}</h5>
                        {
                            change_owner ? 
                            <h5 className='text-left text-2xl font-bold'>{t('auth.alert.serial_number_not_for_this_user')}</h5>:
                            <h5 className='text-left text-5xl font-bold'>{t('welcome')}</h5>
                        }
                    </div>
                    { !change_owner && <div className="mt-1 grid px-8">
                        <div className="my-3">
                            <input type="email" name="email" id="email" placeholder='Email Address' onChange={(e) => set_email(e.target.value)} value={email} className="block w-full input-1"/>
                        </div>
                        <div className="my-3">
                            <input type="password" name="password" id="password" placeholder='Password' onChange={(e) => set_password(e.target.value)} value={password} className="block w-full input-1"/>
                        </div>
                    </div>}
                    { !change_owner && <div className='flex justify-between px-8'>
                        <div className='cursor-pointer'>
                            <input type='checkbox' className='mr-2 my-auto' id='remember_me' value={remember_me} name='remember_me' onChange={() => set_remember_me(!remember_me)}/>
                            <label className='text-sm text-gray-500 my-auto' for="remember_me">{t('auth.remember_me')}</label>
                        </div>
                        <label onClick={() => navigate('/forgot-password')} className='text-sm text-gray-500 cursor-pointer'>{t('auth.forgot_password')}</label>
                    </div>}
                    <div className="mt-6 px-8">
                        { loading ? 
                            <div role="status" className="mt-2 flex justify-center">
                                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div> : 
                            <>
                                {   change_owner ? 
                                    <div className="mt-2 flex">
                                        <button type='button' className='btn-register' onClick={(e) => requestChangeOwner()}>{t('send_request')}</button> &nbsp; &nbsp; &nbsp; &nbsp;
                                        <button type='button' className='btn-resend' onClick={(e) => set_change_owner(false)}>{t('back')}</button>
                                    </div>
                                    : 
                                    <div className="mt-2 flex">
                                        <button type='button' className='btn-register' onClick={(e) => Login()}>{t('auth.login')}</button> &nbsp; &nbsp; &nbsp; &nbsp;
                                        <button type='button' className='btn-resend' onClick={(e) => navigate('/register')}>{t('auth.sign_up')}</button>
                                    </div>
                                }
                            </>
                        }
                    </div>
                </div>
            )
        }
    }

    return(
        <>
            <form>
                <div class="grid grid-cols-2 gap-4 mb-4">
                    {formPage()}
                    <div className="background-page" style={{height:'100vh'}}>
                    </div>
                </div>
            </form>
        </>
    );
}