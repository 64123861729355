import { useTranslation } from "react-i18next";
import useCMSRepositories from "../repositories/cms";
import moment from "moment/moment"
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getCustomer, getCustomerDevice } from "../helpers/auth-helper";
import { getData } from "../helpers/api-helper";
import { CircularProgressbar,CircularProgressbarWithChildren,buildStyles  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Carousel, CarouselControl, CarouselIndicators, CarouselItem } from "reactstrap";
import Register from "./Register";
import RegisterDevice from "../components/RegisterDevice";
import DeviceOnService from "../components/DeviceOnService";

export default function Dashboard(props){

    const { i18n, t } = useTranslation();
    const navigate = useNavigate()

    const [month_percentage,set_month_percentage] = useState(0)
    const [register_device,set_register_device] = useState(false)
    const [devices,set_devices] = useState([])
    const [today,setToday] = useState("")
    const customer = getCustomer()

    const [loading,set_loading] = useState(false)

    const [page,set_page] = useState(1);

    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useCMSRepositories(page,6)

    useEffect(() => {
        getThisDevice()
    },[])

    const getThisDevice=async(serial_number = localStorage.getItem('serial_number'))=>{
        // var serial_number = localStorage.getItem('serial_number');
        set_loading(true)
        var params = {customer_code:customer.customer_code}
        const response = await getData(`${process.env.REACT_APP_API}/user-devices`,params)
        set_loading(false)

        if(response?.status == 200){
            var new_devices = response?.data?.data
            for (let device of new_devices) {
                device.month_percentage = await runCircular(device)
            }
            set_devices(new_devices)
            // localStorage.setItem("serial_number",response?.data.data[0]?.serial_number)
            // localStorage.setItem('customer_device',JSON.stringify(response?.data.data[0]))
        }else{
            var device = localStorage.getItem('customer_device')
            runCircular(JSON.parse(device))
            set_devices(JSON.parse(device))
        }
    }

    const dayLeftInAMonth=(date)=>{
        let currentDate = new Date(date)
        var currentYear = currentDate.getFullYear();
        var currentMonth = currentDate.getMonth();

        var currentMonthLastDate = (new Date(currentYear, currentMonth + 1, 0)).getDate() + 1;

        var daysLeftInMonth = currentMonthLastDate - currentDate.getDate();
        // console.log(currentMonthLastDate,"currentMonthLastDate")
        return daysLeftInMonth
    }

    const runCircular= async (data = devices)=>{

        let newDate = new Date(data?.register_date)
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1; // real use
        let year = newDate.getFullYear();

        let newDateExpired = new Date(data?.expired_date)
        let date_expired = newDateExpired.getDate();
        let month_expired = newDateExpired.getMonth() + 1; // real use
        let year_expired = newDateExpired.getFullYear();
        let expired_date = year_expired +"-"+ month_expired +"-"+ date_expired // real use

        var warranty_month = data?.warranty_month
        // console.log(warranty_month,"warranty_month")
        // console.log(expired_date,"expired_date")
        var limit_day = 0;
        var month_count = month
        var year_count = year
        var date_count = date
        var call_month = ""
        for(var i = 0;i<warranty_month;i++){
            call_month = year_count +"-"+ month_count +"-"+date_count
            // console.log(call_month,"call_month")
            var daysLeftInMonth = i == 0 ? dayLeftInAMonth(call_month)-1 : dayLeftInAMonth(call_month)
            // console.log(daysLeftInMonth,"daysLeftInMonth");
            limit_day += daysLeftInMonth
            date_count = 1
            if(month_count == 12){
                month_count = 1
                year_count += 1
            }else{
                month_count += 1
            }
            // console.log(limit_day,"limit day")
            if(warranty_month-1 == i && expired_date != call_month){
                // console.log('last months',expired_date)
                // console.log(date,"daysLeftInMonth");
                limit_day += date
                // console.log(limit_day,"limit day")
            }
        }
        let newDateToday = new Date()
        let today_date = newDateToday.getDate();
        let today_month = newDateToday.getMonth() + 1; // real use
        let today_year = newDateToday.getFullYear();
        let today = today_year +"-"+ today_month +"-"+ today_date // real use
        // let today = '2024-11-05' // simulation
        setToday(today)
        var day_left = daysBetween(today, data?.expired_date)
        var day_percentage = (parseInt(day_left)/limit_day)*100;
        // console.log(parseInt(day_left),"day_left")
        // console.log(day_percentage,"day_percentage")    
        set_month_percentage(day_percentage)
        return day_percentage
    }

    function stripHtml(html){
        let tmp = document.createElement("DIV");
        tmp.innerHTML = html;
        return tmp.textContent || tmp.innerText || "";
     }

    function treatAsUTC(date) {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    }
    
    function daysBetween(startDate, endDate) {
        var millisecondsPerDay = 24 * 60 * 60 * 1000;
        return (treatAsUTC(endDate) - treatAsUTC(startDate)) / millisecondsPerDay;
    }
    
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
  
    const next = () => {
      if (animating) return;
      const nextIndex = activeIndex === devices.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    };
  
    const previous = () => {
      if (animating) return;
      const nextIndex = activeIndex === 0 ? devices.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    };
  
    const goToIndex = (newIndex) => {
      if (animating) return;
      setActiveIndex(newIndex);
    };

    const slides = devices.length > 0 && devices.map((item) => {
        return(
            <CarouselItem
            onExiting={() => setAnimating(true)}
            onExited={() => setAnimating(false)}
            key={item.serial_number}
            >
                <div className={"flex flex-row flex-wrap justify-center pb-5"}>
                    {   loading ?
                            <div role="status" className="mt-2 flex justify-center">
                                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                        :
                        <div class="py-2 px-3 text-left rounded bg-white" style={{width:320}}>
                            <div className="flex flex-row">
                                <h2 className="font-bold text-xl mr-auto">{item?.serial_number ? item.serial_number : 'Unregistered Device'}</h2>
                                <div className="my-auto cursor-pointer">
                                    <DeviceOnService serial_number={item.serial_number} on_service={item.on_service} type={'serial_number'}/>
                                </div>
                            </div>
                            { item?.serial_number &&
                                <div className="flex">
                                    <label className="text-sm text-gray-500">{t('serial_number')}</label> &nbsp;
                                    <div className="m-auto flex flex-row" onClick={() => navigator.clipboard.writeText(item.serial_number)}>
                                        <label className="text-xs m-auto text-gray-500">{item.serial_number}</label> &nbsp; &nbsp;
                                        <div className="my-auto">
                                            <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.12502 8.875H2.50099C2.33684 8.87513 2.17428 8.84294 2.02257 8.78024C1.87086 8.71755 1.733 8.62558 1.61684 8.5096C1.50067 8.39362 1.40848 8.25589 1.34554 8.10428C1.2826 7.95267 1.25015 7.79016 1.25002 7.62601V2.00101C1.24988 1.83669 1.28213 1.67395 1.34496 1.52211C1.40778 1.37027 1.49994 1.23231 1.61614 1.11612C1.73233 0.999927 1.87027 0.907793 2.02211 0.844971C2.17395 0.782149 2.33667 0.749869 2.50099 0.75H8.12599C8.29023 0.75 8.45285 0.78238 8.60457 0.845261C8.75629 0.908142 8.89418 1.00029 9.01027 1.11647C9.12636 1.23265 9.21838 1.37057 9.28114 1.52234C9.3439 1.67411 9.37612 1.83677 9.37599 2.00101V2.625M6.87599 5.125H12.501C12.6651 5.12487 12.8277 5.15708 12.9794 5.21977C13.1312 5.28247 13.269 5.37444 13.3852 5.49042C13.5014 5.6064 13.5935 5.74413 13.6564 5.89574C13.7194 6.04734 13.7519 6.20986 13.752 6.37401V11.999C13.7522 12.1633 13.7199 12.3261 13.6571 12.4779C13.5942 12.6297 13.5021 12.7677 13.3859 12.8839C13.2697 13.0001 13.1317 13.0922 12.9799 13.155C12.828 13.2179 12.6653 13.2501 12.501 13.25H6.87599C6.71176 13.25 6.54914 13.2176 6.39742 13.1548C6.24569 13.0919 6.10786 12.9997 5.99178 12.8835C5.87569 12.7674 5.7836 12.6294 5.72084 12.4777C5.65808 12.3259 5.62586 12.1632 5.62599 11.999V6.37401C5.62626 6.04283 5.75797 5.7253 5.99214 5.49112C6.22632 5.25694 6.54482 5.12527 6.87599 5.125Z" stroke="#757575" stroke-linecap="round" stroke-linejoin="round"/>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            }
                                <div className="flex flex-col">
                                { item.on_service !== null &&
                                    <DeviceOnService serial_number={item.serial_number} on_service={item.on_service} type={'on_service'}/>
                                }
                                <img src={item.image || require('../assets/unregistered_device.png')} className="mx-auto my-2" width={'80%'} height={'80%'}/>
                                {
                                    item?.serial_number && 
                                    <div className="flex flex-row">
                                        <div className="w-24">
                                        <CircularProgressbarWithChildren value={item.month_percentage}
                                        styles={buildStyles({
                                            rotation: 0.25,
                                            strokeLinecap: 'round',
                                            textColor:'#EB008B',
                                            pathTransitionDuration: 0.5,
                                            pathColor: `#EB008B`,
                                            textColor: '#EB008B',
                                            trailColor: '#d6d6d6',
                                            backgroundColor: '#3e98c7',
                                        })}>
                                        {   item.month_percentage > 0 ?
                                            <div className="text-center">
                                                <p className="text-[10px]">{t('expired_at')} </p>
                                                <p className="font-semibold text-[10px]" style={{color:'#EB008B'}}>
                                                    {item && moment(item.expired_date).format('Do MMMM YYYY')}
                                                </p>
                                            </div> : 
                                            <div className="text-center">
                                                <p className="font-semibold mt-auto text-[8px]" style={{color:'#EB008B'}}>
                                                    {t('warranty_expired')}
                                                </p>
                                            </div>
                                        }
                                        </CircularProgressbarWithChildren>
                                        </div>
                                        <div className="ml-5 my-auto">
                                            <h4 className="font-bold text-base">{t('registered')} </h4>
                                            <p className="font-semibold text-sm" style={{color:'#EB008B'}}>
                                                {item && moment(item.register_date).format('Do MMMM YYYY')}
                                            </p>
                                        </div>
                                    </div> }
                            </div>
                        </div>
                        }
                        { item?.ms_products?.specifications.length > 0 &&
                            <div class="text-left p-4 rounded bg-white border-b-8 beranda-item">
                            <h2 className="font-bold text-xl mb-3">{'System Specification'}</h2>
                            <div className="grid grid-cols-3 gap-1">
                                {
                                    item?.ms_products.specifications.sort((a, b) => b.id - a.id).map((row) =>
                                        <div className="flex flex-col my-2 w-44">
                                            <label className="text-sm font-semibold">{row.spec.name}</label>
                                            <label className="text-sm line-clamp-2 whitespace-wrap text-gray-600">{row.value}</label>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        }
                    </div>
            </CarouselItem>
        )
    })

    return(
        <div className="dashboard-page">
        {
            register_device ? 
            <RegisterDevice set_register_device={set_register_device} customer_code={customer.customer_code} getThisDevice={getThisDevice} serial_number={devices?.serial_number}/> :
            <>
                {devices.length > 0 ? 
                <div>
                    <Carousel
                        activeIndex={activeIndex}
                        interval={false}
                        dark
                        next={next}
                        previous={previous}>
                        <CarouselIndicators
                            items={devices}
                            activeIndex={activeIndex}
                            onClickHandler={goToIndex}
                        />
                        {slides}
                        <CarouselControl
                            direction="prev"
                            className=""
                            directionText="Previous"
                            onClickHandler={previous}
                        />
                        <CarouselControl
                            direction="next"
                            directionText="Next"
                            onClickHandler={next}
                        />
                    </Carousel>
                    {/* <div className="bg-white w-28 h-28 border-b-2 my-auto rounded-md p-2 beranda-item cursor-pointer" onClick={() => set_register_device(true)}>
                        <div className="m-auto">
                            <p className="text-center my-auto font-bold">{t('add_device')}</p>
                            <svg className="m-auto" xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 24 24" fill="rgba(235,0,139,1)"><path d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22ZM11 11H7V13H11V17H13V13H17V11H13V7H11V11Z"></path></svg>
                        </div>
                    </div> */}
                    <div className="">
                        <button type="button" onClick={() => set_register_device(true)} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                            + {t('add_device')}
                        </button>
                    </div>
                </div> : 
                
                <div className={"flex flex-row flex-wrap justify-start pb-5"}>
                    {   loading ?
                        <div role="status" className="mt-2 flex justify-center">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div> :
                        <div class="py-2 px-3 text-left rounded bg-white" style={{width:320}}>
                            <h2 className="font-bold text-xl">{'Unregistered Device'}</h2>
                            <img src={require('../assets/unregistered_device.png')} className="mx-auto" width={'80%'} height={'80%'}/>
                            <div className="flex m-auto">
                                <svg width="30" height="30" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.25 7.43799V11.188M10.25 14.938H10.259M8.95099 2.64899L1.241 15.967C0.814003 16.707 0.598987 17.075 0.630987 17.379C0.644822 17.5097 0.685881 17.636 0.751501 17.7498C0.817122 17.8637 0.905861 17.9625 1.012 18.04C1.26 18.219 1.68601 18.219 2.53901 18.219H17.961C18.815 18.219 19.242 18.219 19.488 18.04C19.5946 17.963 19.6837 17.8643 19.7494 17.7504C19.8151 17.6364 19.8558 17.5099 19.869 17.379C19.902 17.079 19.687 16.707 19.26 15.967L11.548 2.64899C11.122 1.91399 10.909 1.549 10.631 1.422C10.5111 1.36856 10.3813 1.34094 10.25 1.34094C10.1187 1.34094 9.9889 1.36856 9.86899 1.422C9.59099 1.544 9.37799 1.91399 8.95099 2.64899Z" stroke="#D10909" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <label className="ml-2 text-xs m-auto text-gray-500">{t('register_your_device')}</label> &nbsp;
                                <button type="button" onClick={() => set_register_device(true)} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                                    {t('auth.register')}
                                </button>
                            </div>
                        </div>
                    }
                </div>
                }
            </>
            }
                {/* <div className="flex flex-row justify-evenly">
                    <div class="py-3 px-5 text-left rounded bg-white mt-3 flex">
                        <label className="text-sm text-gray-500 m-auto">{t('apps_update')}</label>
                        <button type="button" onClick={() => updatePWA()} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                            {t('update')}
                        </button>
                    </div>
                </div> */}
                <div class="py-2 text-left mt-1">
                    <a href="#" class="flex items-center p-2 text-gray-600 rounded-lg dark:text-gray-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10.75 1.99899H6.75C4.65 1.99899 3.6 1.999 2.798 2.409C2.09253 2.76872 1.51859 3.34196 1.158 4.047C0.750005 4.847 0.75 5.89999 0.75 7.99899V17.25C0.75 19.35 0.750005 20.4 1.158 21.203C1.51859 21.9083 2.09249 22.4819 2.798 22.842C3.598 23.25 4.65 23.25 6.75 23.25H16.001C18.101 23.25 19.151 23.25 19.953 22.842C20.6579 22.4817 21.2311 21.9081 21.591 21.203C22.001 20.403 22.001 19.35 22.001 17.25V13.25M22.152 1.85001C22.6772 2.37433 23.0352 3.04267 23.1804 3.77048C23.3257 4.49828 23.2518 5.25282 22.9681 5.93861C22.6844 6.62441 22.2036 7.21062 21.5866 7.62308C20.9696 8.03554 20.2442 8.25571 19.502 8.25571C18.7598 8.25571 18.0344 8.03554 17.4174 7.62308C16.8004 7.21062 16.3196 6.62441 16.0359 5.93861C15.7522 5.25282 15.6783 4.49828 15.8236 3.77048C15.9688 3.04267 16.3268 2.37433 16.852 1.85001C17.5553 1.14885 18.5079 0.755127 19.501 0.755127C20.4941 0.755127 21.4467 1.14885 22.15 1.85001H22.152Z" stroke="#1D1B20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        <span class="ms-3">{t('sidebar.news')}</span>
                    </a>
                    <div class="grid grid-cols-3 gap-4 mb-4">
                        {
                            listData?.data.length > 0 && listData.data.slice(0,3).map((row) => 
                                <div class="p-3 text-left rounded bg-white mt-2" onClick={() => navigate("/news/detail/"+row.id,{state:{row,side_data:listData.data.slice(0,6)}})}>
                                    <a href="#" class="flex text-xs items-center text-blue-600 rounded-lg dark:text-blue-600 hover:bg-gray-100 dark:hover:bg-gray-200 group">
                                        {t('source')}
                                    </a>
                                    <h3 className="font-bold text-xl">{row.title}</h3>
                                    <p className="text-xs font-semibold line-clamp-3">{stripHtml(row.description)}</p>
                                    <p className="mt-2 text-xs text-gray-500">{moment(row.created_at).format('LLLL')}</p>
                                </div>
                            )
                        }
                    </div>
                </div>
        </div>
    )
}