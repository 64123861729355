import { createBrowserRouter } from "react-router-dom";

import GuestRoute from "./middleware/guest";
import ProtectedRoute from "./middleware/protected";

import Landing from "../pages/Landing";
import Register from "../pages/Register";
import Error from "../pages/errors/Error";
import Dashboard from "../pages/Dashboard";
import MyDevice from "../pages/MyDevice";
import News from "../pages/News";
import ServiceCenter from "../pages/ServiceCenter";
import ServiceDetail from "../pages/ServiceDetail";
import NewsDetails from "../pages/NewsDetail";
import Login from "../pages/Login";
import ForgotPassword from "../pages/ForgotPassword";
import Settings from "../pages/settings";
import ChangeProfile from "../pages/settings/ChangeProfile";
import ChangePassword from "../pages/settings/ChangePassword";
import Promo from "../pages/Promo";
import CustomerService from "../pages/CustomerService";
import Verification from "../pages/Verification";
import Notifications from "../pages/Notifications";
import CheckSN from "../pages/CheckSN";
import FAQ from "../pages/FAQ";
import FAQDetails from "../pages/FAQDetail";

const routeList = [
    {
		errorElement: process.env.NODE_ENV === "development" ? undefined : <Error />,
        children : [
            //guest
			{
				path: "/",
				exact: true,
				element: (
					<GuestRoute>
						<Landing title={'Landing'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/check-sn",
				exact: true,
				element: (
					<GuestRoute>
						<CheckSN title={'Check Serial Number'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/login",
				exact: false,
				element: (
					<GuestRoute>
						<Login title={'login'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/register",
				exact: false,
				element: (
					<GuestRoute>
						<Register title={'Register'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/forgot-password",
				exact: false,
				element: (
					<GuestRoute>
						<ForgotPassword title={'Forgot Password'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/verification",
				exact: false,
				element: (
					<GuestRoute>
						<Verification title={'Verification'} auth={false}/>
					</GuestRoute>
				),
			},
			{
				path: "/dashboard",
				exact: false,
				element: (
					<ProtectedRoute>
						<Dashboard title={'Dashboard'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/my-device",
				exact: false,
				element: (
					<ProtectedRoute>
						<MyDevice title={'MyDevice'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/news",
				exact: false,
				element: (
					<ProtectedRoute>
						<News title={'News'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/news/detail/:id",
				exact: false,
				element: (
					<ProtectedRoute>
						<NewsDetails title={'News Detail'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/service-center",
				exact: false,
				element: (
					<ProtectedRoute>
						<ServiceCenter title={'Service Center'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/service-center/detail/:id",
				exact: false,
				element: (
					<ProtectedRoute>
						<ServiceDetail title={'Service Center Detail'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/settings",
				exact: false,
				element: (
					<ProtectedRoute>
						<Settings title={'Settings'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/change-profile",
				exact: false,
				element: (
					<ProtectedRoute>
						<ChangeProfile title={'Change Profile'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/change-password",
				exact: false,
				element: (
					<ProtectedRoute>
						<ChangePassword title={'Change Password'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/promo",
				exact: false,
				element: (
					<ProtectedRoute>
						<Promo title={'Promo Menarik'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/customer-service",
				exact: false,
				element: (
					<ProtectedRoute>
						<CustomerService title={'Customer Service'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/notifications",
				exact: false,
				element: (
					<ProtectedRoute>
						<Notifications title={'Notifications'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/FAQ",
				exact: false,
				element: (
					<ProtectedRoute>
						<FAQ title={'FAQ'} auth={false}/>
					</ProtectedRoute>
				),
			},
			{
				path: "/FAQ/detail/:id",
				exact: false,
				element: (
					<ProtectedRoute>
						<FAQDetails title={'FAQ Detail'} auth={false}/>
					</ProtectedRoute>
				),
			},
        ]
    }
]


const routes = process.env.NODE_ENV === "development" ? createBrowserRouter(routeList) : createBrowserRouter(routeList);

export default routes;