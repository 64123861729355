
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";
import QRScanner from "../components/QRScanner.tsx";
import { getData } from "../helpers/api-helper";
import moment from "moment/moment"
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { CircularProgressbarWithChildren,buildStyles  } from 'react-circular-progressbar';
import RunCircular from "../helpers/circular-helper.js";
import toast from "react-hot-toast";
import DeviceOnService from "../components/DeviceOnService.js";
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"
import BarcodeScannerComponent from "react-qr-barcode-scanner";

const CheckSN=()=>{

    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()
    const { t } = useTranslation();
    const [check, set_check] = React.useState(false);
    const [scan_barcode, set_scan_barcode] = React.useState(false);
    const [loading, set_loading] = React.useState(false);
    const [serial_number,set_serial_number] = React.useState(searchParams.get('serial_number') || "")
    const [device,set_device] = React.useState({})

    useEffect(() => {
        if(searchParams.get('forced')){
            searchSerialNumber()
        }
    },[searchParams])

    const onNewScanResult = (decodedText, decodedResult) => {
        console.log(decodedText, decodedResult);
        set_serial_number(decodedText);
        set_scan_barcode(false)
    };

    const searchSerialNumber=async()=>{
        if(serial_number){
            var params = {serial_number}
            set_loading(true)
            const response = await getData(`${process.env.REACT_APP_API}/check-sn`,params)
            set_loading(false)
            if(response?.data){
                console.log(response?.data)
                if(response?.data.output){
                    var new_device = response?.data.output
                    new_device.month_percentage = await RunCircular(new_device[0] || new_device)
                    set_device(new_device[0] || new_device)
                    // toast.success(t(response.data.message))
                }else{
                    toast.error(t(response.data.message))
                }
            }
        }else{
            toast.error(t('auth.serial_number_required'))
            return
        }
    }

    const Back=()=>{
        set_device({})
        set_check(false)
        set_serial_number("")
    }
    
    return(
        <div className="container mx-auto py-5">
            <h1 className="font-bold text-2xl">{t('check_status_warranty')}</h1>
            {   loading &&
                            <div role="status" className="mt-2 flex justify-center">
                                <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>}
                
            {   device?.serial_number ? 
                <div class="py-2 px-3 text-left rounded bg-neutral-100 border-b-sky-50 shadow-md mx-auto" style={{width:320}}>
                            <div className="flex flex-row">
                                <h2 className="font-bold mr-auto text-xl">{device?.serial_number ? device.serial_number : 'Unregistered Device'}</h2>
                                <div className="my-auto cursor-pointer">
                                    <DeviceOnService serial_number={device?.serial_number} on_service={device?.on_service} type={'serial_number'}/>
                                </div>
                            </div>
                    { device?.serial_number &&
                        <div className="flex">
                            <label className="text-sm text-gray-500">{t('serial_number')}</label> &nbsp;
                            <div className="m-auto flex flex-row" onClick={() => navigator.clipboard.writeText(device.serial_number)}>
                                <label className="text-xs m-auto text-gray-500">{device.serial_number}</label> &nbsp; &nbsp;
                                <div className="my-auto">
                                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3.12502 8.875H2.50099C2.33684 8.87513 2.17428 8.84294 2.02257 8.78024C1.87086 8.71755 1.733 8.62558 1.61684 8.5096C1.50067 8.39362 1.40848 8.25589 1.34554 8.10428C1.2826 7.95267 1.25015 7.79016 1.25002 7.62601V2.00101C1.24988 1.83669 1.28213 1.67395 1.34496 1.52211C1.40778 1.37027 1.49994 1.23231 1.61614 1.11612C1.73233 0.999927 1.87027 0.907793 2.02211 0.844971C2.17395 0.782149 2.33667 0.749869 2.50099 0.75H8.12599C8.29023 0.75 8.45285 0.78238 8.60457 0.845261C8.75629 0.908142 8.89418 1.00029 9.01027 1.11647C9.12636 1.23265 9.21838 1.37057 9.28114 1.52234C9.3439 1.67411 9.37612 1.83677 9.37599 2.00101V2.625M6.87599 5.125H12.501C12.6651 5.12487 12.8277 5.15708 12.9794 5.21977C13.1312 5.28247 13.269 5.37444 13.3852 5.49042C13.5014 5.6064 13.5935 5.74413 13.6564 5.89574C13.7194 6.04734 13.7519 6.20986 13.752 6.37401V11.999C13.7522 12.1633 13.7199 12.3261 13.6571 12.4779C13.5942 12.6297 13.5021 12.7677 13.3859 12.8839C13.2697 13.0001 13.1317 13.0922 12.9799 13.155C12.828 13.2179 12.6653 13.2501 12.501 13.25H6.87599C6.71176 13.25 6.54914 13.2176 6.39742 13.1548C6.24569 13.0919 6.10786 12.9997 5.99178 12.8835C5.87569 12.7674 5.7836 12.6294 5.72084 12.4777C5.65808 12.3259 5.62586 12.1632 5.62599 11.999V6.37401C5.62626 6.04283 5.75797 5.7253 5.99214 5.49112C6.22632 5.25694 6.54482 5.12527 6.87599 5.125Z" stroke="#757575" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    }
                        <div className="flex flex-col">
                        { device?.customer_code && device.on_service !== null &&
                            <DeviceOnService serial_number={device.serial_number} on_service={device.on_service} type={'on_service'}/>
                        }
                        <img src={device.image || require('../assets/unregistered_device.png')} className="mx-auto" width={'80%'} height={'80%'}/>
                        {
                            device?.register_date ? 
                            <div className="flex flex-row">
                                <div className="w-24">
                                <CircularProgressbarWithChildren value={device.month_percentage}
                                styles={buildStyles({
                                    rotation: 0.25,
                                    strokeLinecap: 'round',
                                    textColor:'#EB008B',
                                    pathTransitionDuration: 0.5,
                                    pathColor: `#EB008B`,
                                    textColor: '#EB008B',
                                    trailColor: '#d6d6d6',
                                    backgroundColor: '#3e98c7',
                                })}>
                                {   device.month_percentage > 0 ?
                                    <div className="text-center">
                                        <p className="text-[10px]">{t('expired_at')} </p>
                                        <p className="font-semibold text-[10px]" style={{color:'#EB008B'}}>
                                            {device && moment(device.expired_date).format('Do MMMM YYYY')}
                                        </p>
                                    </div> : 
                                    <div className="text-center">
                                        <p className="font-semibold mt-auto text-[8px]" style={{color:'#EB008B'}}>
                                            {t('warranty_expired')}
                                        </p>
                                    </div>
                                }
                                </CircularProgressbarWithChildren>
                                </div>
                                <div className="ml-5 my-auto">
                                    <h4 className="font-bold text-base">{t('registered')} </h4>
                                    <p className="font-semibold text-sm" style={{color:'#EB008B'}}>
                                        {device && moment(device.register_date).format('Do MMMM YYYY')}
                                    </p>
                                </div>
                            </div> : 
                            <div className="flex m-auto">
                                <svg width="30" height="30" viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10.25 7.43799V11.188M10.25 14.938H10.259M8.95099 2.64899L1.241 15.967C0.814003 16.707 0.598987 17.075 0.630987 17.379C0.644822 17.5097 0.685881 17.636 0.751501 17.7498C0.817122 17.8637 0.905861 17.9625 1.012 18.04C1.26 18.219 1.68601 18.219 2.53901 18.219H17.961C18.815 18.219 19.242 18.219 19.488 18.04C19.5946 17.963 19.6837 17.8643 19.7494 17.7504C19.8151 17.6364 19.8558 17.5099 19.869 17.379C19.902 17.079 19.687 16.707 19.26 15.967L11.548 2.64899C11.122 1.91399 10.909 1.549 10.631 1.422C10.5111 1.36856 10.3813 1.34094 10.25 1.34094C10.1187 1.34094 9.9889 1.36856 9.86899 1.422C9.59099 1.544 9.37799 1.91399 8.95099 2.64899Z" stroke="#D10909" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <label className="ml-2 text-xs m-auto text-gray-500">{t('register_your_device')}</label> &nbsp;
                                <button type="button" onClick={() => navigate('/register')} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                                    {t('auth.register')}
                                </button>
                            </div>
                        }
                    </div>
                    <button type="button" onClick={() => Back()} className="bg-transparent border-2 border-blue-500 mt-2 text-blue-500 w-auto p-2 text-sm rounded-md">
                        {t('back')}
                    </button>
                </div>
                :
                <div>
                    {scan_barcode ? 
                        <div>
                            <div className="flex flex-row justify-center">
                                <BarcodeScannerComponent
                                    width={500}
                                    height={500}
                                    onUpdate={(err, result) => {
                                        if (result) onNewScanResult(result.text);
                                    }}
                                />
                            </div>
                        <div className="flex mt-2 justify-center">
                            <button className={'bg-blue-500 text-white p-2 text-sm rounded-md'} onClick={(e) => set_scan_barcode(false)}>{t('back')}</button>
                        </div>
                        </div> :
                        <div className="flex flex-col items-center">
                            <div>
                                <input type="text" name="serial_number" onChange={(e) => set_serial_number(e.target.value)} value={serial_number} placeholder={t('input_sn')} className="border border-gray-300 rounded-md p-2 w-80 mt-5 text-sm"/>
                                <div className="mt-2 flex justify-center">
                                    <p className='color-lvl-4'>Or</p>
                                </div>
                                <div className="flex justify-center">
                                    <button className={'bg-blue-500 text-white p-2 text-sm rounded-md'} onClick={(e) => set_scan_barcode(true)}>{'Scan Barcode'}</button>
                                </div>
                                <div className="mt-3 flex flex-row items-start cursor-pointer" onClick={() => console.log('how to identify')}>
                                    {/* <QuestionMark className="w-5 h-5 inline-block mr-1"/> */}
                                    <p className="text-xs text-neutral-600 my-auto">{t('how_to_identify_sn')} ?</p>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="mt-3 flex w-96 flex-row mx-auto items-center">
                        <input type="checkbox" className="w-8 mb-auto" value={check} onChange={() => set_check(!check)}/>
                        <p className="text-xs text-left w-80 mt-auto">{t('aggrement_text')} <a href="#" className="text-blue-700">{t('privacy_policy')}</a> ADVAN</p>
                    </div>
                    <div>
                        <button disabled={!check} onClick={() => searchSerialNumber()} className={`${check ? 'bg-blue-700' : 'bg-blue-300'} text-white w-80 p-2 rounded-md mt-5`}>{t('send')}</button>
                    </div>
                </div>
            }
        </div>
    )

}

export default CheckSN;