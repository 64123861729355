import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from 'react-router-dom';
import useFAQRepositories from "../repositories/faq";
import { Collapse } from "reactstrap";

export default function FAQ(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const page = searchParams.get('page');
    const { data: listData, isLoading: isLoadingListData, mutate: mutateListData } = useFAQRepositories(page, 15);

    useEffect(() => {
        mutateListData();
    }, [page]);

    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleDescription = (index) => {
        console.log('tai')
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    return (
        <div className="berita-page">
            <a href="/FAQ" className="flex font-bold text-black no-underline text-lg items-center p-2 rounded-lg hover:bg-gray-100 dark:hover:bg-gray-200 group">
                <svg width="28" height="28" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M10.75 1.99899H6.75C4.65 1.99899 3.6 1.999 2.798 2.409C2.09253 2.76872 1.51859 3.34196 1.158 4.047C0.750005 4.847 0.75 5.89999 0.75 7.99899V17.25C0.75 19.35 0.750005 20.4 1.158 21.203C1.51859 21.9083 2.09249 22.4819 2.798 22.842C3.598 23.25 4.65 23.25 6.75 23.25H16.001C18.101 23.25 19.151 23.25 19.953 22.842C20.6579 22.4817 21.2311 21.9081 21.591 21.203C22.001 20.403 22.001 19.35 22.001 17.25V13.25M22.152 1.85001C22.6772 2.37433 23.0352 3.04267 23.1804 3.77048C23.3257 4.49828 23.2518 5.25282 22.9681 5.93861C22.6844 6.62441 22.2036 7.21062 21.5866 7.62308C20.9696 8.03554 20.2442 8.25571 19.502 8.25571C18.7598 8.25571 18.0344 8.03554 17.4174 7.62308C16.8004 7.21062 16.3196 6.62441 16.0359 5.93861C15.7522 5.25282 15.6783 4.49828 15.8236 3.77048C15.9688 3.04267 16.3268 2.37433 16.852 1.85001C17.5553 1.14885 18.5079 0.755127 19.501 0.755127C20.4941 0.755127 21.4467 1.14885 22.15 1.85001H22.152Z" stroke="#1D1B20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <span className="ms-3">{t('sidebar.faq')}</span>
            </a>
            <div className="faq-content w-auto">
                {listData?.data.length > 0 && listData.data.map((row, index) => (
                    <div key={index} className={"p-3 text-left rounded bg-white mt-2 mr-2 cursor-pointer break-inside-avoid"} onClick={() => toggleDescription(index)}>
                       
                        <div className="">
                            <h3 className={`font-bold text-2xl ${expandedIndex === index ? '' : 'line-clamp-2'}`}>{row.title}</h3>
                            <Collapse isOpen={expandedIndex === index}>
                            <p className="text-xs" dangerouslySetInnerHTML={{__html: row.description}}/>
                            </Collapse>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}