
export default {
    translation: {
        title: 'Vancare App',
        sidebar:{
            dashboard: 'Beranda',
            my_device: 'Perangkat Saya',
            interesting_promo: 'Promo Menarik',
            news: 'Berita Terbaru',
            customer_service: 'Layanan Pelanggan',
            service_center: 'Pusat Pelayanan',
            settings: 'Pengaturan',
            notifications:"Notifikasi",
            faq:"Pertanyaan Umum",
        },
        empty:"Kosong",
        serial_number: "Nomor Serial",
        apps_update :"Pembaharuan Aplikasi",
        update: "Pembaharui",
        register_your_device:"Segera daftarkan produkmu untuk mengaktifkan garansi",
        active_warranty: "Garansi Aktif",
        until: "s/d",
        system_information:"Informasi sistem",
        processor:"Prosesor",
        memory:"Memori",
        storage:"Penyimpanan",
        screen:"Layar",
        camera:"Kamera",
        material:"Material",
        system_operation:"Sistem Operasi",
        source: "Sumber",
        address: "Address",
        outlet_detail: "Detail Outlet",
        contact_name: "Nama Kontak",
        contact_email: "Email Kontak",
        contact_number: "Nomor Kontak",
        hello:"Hallo",
        send:"Kirim",
        send_request:"Kirim permintaan",
        request_sended:"Permintaan terkirim",
        back:"Kembali",
        welcome:"Selamat Datang!",
        news_detail:"Detail Berita",
        faq_detail:"Detail Pertanyaan Umum",
        registered:"Terdaftar pada",
        expired_at:"Masa berlaku",
        chose_model:"Pilih Model",
        chose_system_name:"Pilih Tipe Device",
        register_device:"Daftar Perangkat",
        add_device:"Tambah Perangkat",
        auth:{
            add:"Tambah",
            agree:"Setuju",
            disagree:"Tidak Setuju",
            login:"Masuk",
            register:"Daftar",
            sign_up:"Daftar",
            remember_me:"Ingat Saya",
            forgot_password:"Lupa Password?",
            message_verification:"Kita telah mengirim kode verifikasi ke email",
            message_name_diff:"Email sudah terdaftar, tetapi nama penuh berbeda dengan nama penuh anda sebelumnya, mohon konfirmasi nama penuh mana yang akan digunakan",
            change_email:"Ubah Email",
            full_name:"Nama Penuh",
            new_full_name:"Nama Penuh Baru",
            new_email:"Email Baru",
            phone:"Nomor HP",
            placeholder_phone:"Nomor HP mulai dengan 08xxxxxxxx",
            verify:'Verifikasi',
            resend:'Kirim Ulang',
            password_mismatch:"Password Tidak cocok",
            current_password:"Password Sekarang",
            new_password:"Password baru",
            password_is_too_short:"Password terlalu pendek (min 8 karakter)",
            apply:'Terapkan',
            cancel:'Batalkan',
            serial_number_required:"Serial Number Wajib",
            device_name_required:"Nama Perangkat Wajib",
            model_required:"Model Wajib",
            system_name_required:"Tipe Device Wajib",
            password_required:"Password Wajib",
            phone_required:"Phone Number Wajib",
            confirm_password_required:"Confirm Password Wajib",
            choose_sn:"Mohon pilih device mana yang anda gunakan",
            serial_number_owned_by_another_user:"Serial Number telah terdaftar pada email lain",
            serial_number_not_found:"Serial Number tidak ditemukan",
            device_registered:"Perangkat Terdaftar",
            device_already_registered:"Perangkat Sudah Terdaftar",
            email_already_registered:"Email Sudah Terdaftar",
            user:{
                not_found:"User tidak ditemukan",
                not_verified:"User Belum verifikasi token"
            },
            token:{
                verified:"Akun Terverifikasi",
                invalid:"Token Salah",
            },
            alert:{
                verify_success_but_name:"Email ini sudah terdaftar, tetapi nama penuh berbeda dengan nama penuh anda sebelumnya",
                verify_success:"Verifikasi Berhasil",
                change_email_success:"Ubah Email Berhasil",
                email_format_invalid:'Email Format Salah',
                email_format_valid:'Email Format Benar',
                register_success:"Daftar Berhasil",
                account_exists:"Akun Sudah terdaftar",
                token_resended:"Token terkirim ulang",
                change_name_success:"Ubah Nama Berhasil",
                change_password_success:"Ubah Password Berhasil",
                serial_number_already_exist:"Serial Number telah terdaftar",
                device_hasnot_registered:"Device belum terdaftar, silahkan daftar terlebih dahulu",
                serial_number_not_for_this_user:"Device Serial Number tidak terdaftar pada email anda, kirim permintaan pemindahan kepemilikan",
                agree_terms:"Mohon setujui syarat dan ketentuan",
                terms_agreed:"Syarat dan ketentuan disetujui",
                device_available_unregistered:"Perangkat tersedia tetapi tidak terdaftar pada akun manapun",
                device_unavailable:"Perangkat tidak terdaftar"
            },
            terms_and_conditions:"Syarat dan Ketentuan",
            chose_how_to_register:"Pilih Cara Registrasi Perangkat Anda",
            explanation:"Penjelasan",
            explanation_text:"Untuk memastikan keaslian dan keamanan produk, kami memerlukan serial number Anda untuk proses registrasi. Serial number ini unik dan hanya diberikan kepada produk yang sah dan asli.",
            reason_of_use:"Alasan Penggunaan Serial Number",
            reason_of_use_1:"Verifikasi Keaslian: Serial number membantu kami memverifikasi bahwa produk Anda adalah asli dan tidak palsu.",
            reason_of_use_2:"Pengamanan: Dengan menggunakan serial number, kami dapat memastikan bahwa produk Anda terlindungi dari akses tidak sah.",
            reason_of_use_3:"Pendaftaran Garansi: Serial number diperlukan untuk mendaftarkan garansi produk Anda.",
            reason_of_use_4:"Dukungan Teknis: Serial number membantu kami memberikan dukungan teknis yang lebih efektif.",
            how_to_protect:"Bagaimana Kami Melindungi Informasi Anda",
            how_to_protect_1:"Kerahasiaan: Kami berjanji untuk menjaga kerahasiaan informasi Anda.",
            how_to_protect_2:"Keamanan: Kami menggunakan teknologi keamanan terkini untuk melindungi informasi Anda.",
            how_to_protect_3:"Kompatibilitas dengan Regulasi: Kami memastikan bahwa penggunaan serial number kami sesuai dengan regulasi dan standar keamanan yang berlaku.",
            agreement:"Persetujuan",
            agreement_text:"Dengan mengklik 'Setuju', Anda memberikan persetujuan untuk:",
            agreement_1:"Memberikan serial number Anda untuk proses registrasi.",
            agreement_2:"Memahami dan menerima kebijakan privasi dan keamanan kami.",
            sn_info:"Jika Anda memiliki pertanyaan atau kekhawatiran, silakan hubungi kami melalui",
            or:"atau",
            settings:{
                change_email:"Ganti Email",
                change_password:"Ganti Password",
                change_profile:"Ganti Profil",
            },
            network_error:"Terjadi Kesalahan pada koneksi internet (Tidak ada jaringan)",
            warranty_expired:"Masa Garansi Habis",
            check_status_warranty:"Pengecekan Status Garansi",
            input_sn:"Masukkan Nomor Seri Unit",
            how_to_identify_sn:"Cara mengidentifikasi Nomor Seri Unit",
            aggrement_text:"Saya setuju untuk memberikan nomor seri produk saya kepada ADVAN untuk menanyakan masa garansi produk saya, dan juga menyetujui",
            privacy_policy:"Kebijakan Privasi",
            are_you_sure:"Apakah anda yakin",
            using_sn:"ingin mengaktifkan garansi perangkat pada akun ini?",
            yes:"Ya",
            no:"Tidak",
            device_on_service:"Perangkat dalam servis",
            link_product_not_found:"Link Produk tidak ditemukan",
            device_neverbeen_onservice:"Perangkat belum pernah servis",
            on_outlet:"On Outlet",
            notes:"Notes"
        }
    }
}

