// Remove token and user from browser storage
export const removeSession = () => {
    localStorage.removeItem('token')
    localStorage.removeItem('customer')
    localStorage.removeItem('user')
    localStorage.removeItem('last_activities')
}

// Set token and user to browser storage
export const setToken = (token) => {
    global.token = token
    localStorage.setItem('token', token)
}

// Set token and user to browser storage
export const setPermission = (permission) => {
    sessionStorage.setItem('permission', permission)
}

export const getPermission = () => {
    const permission = sessionStorage.getItem('permission')
    return permission
}

// Set token and user to browser storage
export const setSession = (token, user,customer) => {
    localStorage.setItem('token', token)
    localStorage.setItem('user', JSON.stringify(user))
    localStorage.setItem('customer', JSON.stringify(customer))
}
// Retrieve user data from session storage
export const getUser = () => {
    const user = localStorage.getItem('user')
    return user ? JSON.parse(user) : null
}
// Retrieve user data from session storage
export const getCustomer = () => {
    const customer = localStorage.getItem('customer')
    return customer ? JSON.parse(customer) : null
}

// Retrieve user data from session storage
export const getCustomerDevice = () => {
    const customer = localStorage.getItem('customer_device')
    return customer ? JSON.parse(customer) : null
}

// Retrieve token from local storage
export const getToken = () => {
    return localStorage.getItem('token') || null
}
// Update user from session storage
export const updateUser = (name, phone) => {
    const user = JSON.parse(sessionStorage.getItem('data_user'))

    if (user) {
        user.name = name
        user.phone = phone

        localStorage.removeItem('data_user')
        localStorage.setItem('data_user', user)
    }
}