import { t } from "i18next";
import React, { useCallback, useEffect, useState } from "react";
import { getData, postData } from "../helpers/api-helper";
import toast from "react-hot-toast";
// import BarcodeScanner from "./BarcodeScanner";
import { Modal, ModalBody } from "reactstrap";
import QRScanner from "./QRScanner.tsx";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { BarcodeScanner } from 'react-barcode-scanner'
import "react-barcode-scanner/polyfill"

const RegisterDevice = (props) => {
    const [how_to_register, set_how_to_register] = useState('');
    const [serial_number, set_serial_number] = useState('');
    const [device_name, set_device_name] = useState('');
    const [brand, set_brand] = useState('');
    const [model, set_model] = useState('');
    const [system_name, set_system_name] = useState('');

    const [data_models, set_data_models] = useState([]);
    const [loading,set_loading] = useState(false)
    const [modal_consent,set_modal_consent] = useState(false)

    const getModel = () => {
        getData(`${process.env.REACT_APP_API}/brand-model`, { paginate: false }).then((response) => {
            if (response) {
                set_data_models(response.data.data);
            }
        }).catch((e) => console.log(e));
    };

    useEffect(() => {
        getModel();
    }, []);

    const validateSubmit = () => {
        set_loading(true)
        if (serial_number === "") {
            set_loading(false)
            toast.error(t('auth.serial_number_required'));
            return false;
        }
        // if (device_name === "") {
        //     toast.error(t('auth.device_name_required'));
        //     return false;
        // }
        // if (model === "") {
        //     toast.error(t('auth.model_required'));
        //     return false;
        // }
        // if (system_name === "") {
        //     toast.error(t('auth.system_name_required'));
        //     return false;
        // }

        Submit();
    };

    function getCurrentDate(separator = '-') {
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        let hours = newDate.getHours();
        let minutes = newDate.getMinutes();
        let seconds = newDate.getSeconds();

        return `${year}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${date < 10 ? `0${date}` : `${date}`} ${hours < 10 ? `0${hours}` : `${hours}`}:${minutes < 10 ? `0${minutes}` : `${minutes}`}:${seconds < 10 ? `0${seconds}` : `${seconds}`}`;
    }

    const Submit = () => {
        var params = {
            customer_code: props.customer_code,
            serial_number: serial_number,
            // device_name: device_name,
            // brand: brand,
            // model: model,
            // system_name: system_name,
            register_date: getCurrentDate('-')
        };
        postData(`${process.env.REACT_APP_API}/register-device`, params).then((response) => {
            set_loading(false)
            if (response.data.status === 200) {
                props.set_register_device(false);
                if(props.serial_number){
                    toast.success(t(response.data.message));
                    props.getThisDevice();
                }else{
                    props.getThisDevice(response.data.data_device.serial_number);
                    toast.success(t(response.data.message));
                    localStorage.setItem('customer_device', JSON.stringify(response.data.data_device));
                    localStorage.setItem('serial_number', serial_number);
                }
            } else if (response.data.status === 201) {
                toast.error(t(response.data.message));
            } else if (response.data.status === 202) {
                toast.error(t(response.data.message));
            }
        }).catch((e) => {
            if (e.response.status === 409) {
                toast.error(t('auth.serial_number_already_exist'));
            } else {
                // console.log(e);
            }
            set_loading(false)
        });
    };

    const onNewScanResult = (barcodes) => {
        // console.log(barcodes);
        set_serial_number(barcodes);
        set_how_to_register('get_device');
    };

    const backPage = () => {
        if (how_to_register === 'scan_barcode' || how_to_register === 'get_device') {
            set_how_to_register('');
        } else if (how_to_register === '') {
            props.set_register_device(false);
        }
    };

    return (
        <div className="py-2 my-auto px-5 text-left rounded bg-white" style={{ width: "60%" }}>
            <h2 className="font-bold text-xl text-left">{t('register_device')}</h2>
            {how_to_register === 'scan_barcode' && 
                // <BarcodeScanner options={{
                //     delay:1000,
                //     formats:['code_128']
                // }} onCapture={(barcodes) => onNewScanResult(barcodes)}/>
                
                    <BarcodeScannerComponent
                        width={500}
                        height={500}
                        onUpdate={(err, result) => {
                            if (result) onNewScanResult(result.text);
                        }}
                    />
            }
            {how_to_register === '' ? (
                <div className="my-2 px-8">
                    <div className='text-center px-5'>
                        <h2 className='color-2'>{t('auth.chose_how_to_register')}</h2>
                    </div>
                    <div className="mt-2 flex justify-center">
                        <button className='btn-register' onClick={(e) => set_how_to_register('get_device')}>{'Get Device Serial Number'}</button>
                    </div>
                    <div className="mt-2 flex justify-center">
                        <p className='color-lvl-4'>Or</p>
                    </div>
                    <div className="mt-2 flex justify-center">
                        <button className={'btn-resend'} onClick={(e) => set_how_to_register('scan_barcode')}>{'Scan Barcode'}</button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="my-2">
                        <input type="text" name="serial_number" id="serial_number" placeholder='Serial Number' onChange={(e) => set_serial_number(e.target.value)} value={serial_number} className="block w-full input-1" />
                    </div>
                    {/* <div className="my-2">
                        <input type="text" name="device_name" id="device_name" placeholder='Device Name' onChange={(e) => set_device_name(e.target.value)} value={device_name} className="block w-full input-1" />
                    </div> */}
                    {/* <div className="my-2">
                        <select name="model" id="model" onChange={(e) => setModelAndBrand(e.target.value)} value={model} className="block w-full input-1">
                            <option selected value={''} disabled>{t('chose_model')}</option>
                            {data_models.map((item) =>
                                <option value={item.name}>{item.name}</option>
                            )}
                        </select>
                    </div>
                    <div className="my-2">
                        <select name="system_name" id="system_name" onChange={(e) => set_system_name(e.target.value)} value={system_name} className="block w-full input-1">
                            <option selected value={''} disabled>{t('chose_system_name')}</option>
                            <option value="android">Android</option>
                            <option value="windows">Windows</option>
                            <option value="chromebook">Chromebook</option>
                        </select>
                    </div> */}
                </div>
            )}
            <div className="flex flex-row justify-between">
                <button type="button" onClick={() => backPage()} className="border-2 border-blue-500 text-blue-500 p-2 text-sm rounded-md">
                    {t('auth.cancel')}
                </button>
                {how_to_register === 'get_device' &&
                    <button type="button" onClick={() => set_modal_consent(true)} className="bg-blue-500 text-white p-2 text-sm rounded-md">
                        {t('auth.add')}
                    </button>}
            </div>
            <Modal isOpen={modal_consent}>
                <ModalBody>
                    <header className="flex">
                        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                        <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>{t('are_you_sure')}</h2>
                    </header>
                    <div className='text-left'>
                        <p>
                            {t('using_sn')}
                        </p>
                        { loading ? 
                        <div role="status" className="mt-2 flex justify-center">
                            <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                            </svg>
                            <span class="sr-only">Loading...</span>
                        </div> : 
                        <div className="mt-2 flex">
                            <button type='button' className='btn-register' onClick={(e) => validateSubmit()}>{t('yes')}</button>&nbsp; &nbsp; &nbsp; &nbsp;
                            <button type='button' className='btn-resend' onClick={(e) => set_modal_consent(false)}>{t('no')}</button>
                        </div>
                        }
                    </div>
                </ModalBody>
            </Modal>
        </div>
    );
};

export default RegisterDevice;