import React, { useState,useEffect } from 'react';
import toast from 'react-hot-toast';
import { postData } from '../helpers/api-helper';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { Alert } from 'reactstrap';

export default function Register(props){

    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const [serial_number,set_serial_number] = useState("")
    const [full_name,set_full_name] = useState("")
    const [phone,set_phone] = useState("")
    const [email,set_email] = useState("")
    const [password,set_password] = useState("")
    const [confirm_password,set_confirm_password] = useState("")

    const [seconds,set_seconds] = useState(0)
    const [loading,set_loading] = useState(false)
    const [is_email_valid,set_is_email_valid] = useState(false)
    const [agreement,set_agreement] = useState(true)
    const [type_sn,set_type_sn] = useState('get_device')

    const navigate = useNavigate()

    useEffect(() => {
        const timeOutId = setTimeout(() => handleOnChange(email), 1500);
        return () => clearTimeout(timeOutId);
    }, [email]);
    
    const handleOnChange = ( email ) => {

        // don't remember from where i copied this code, but this works.
        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if ( (re.test(email) && email != "") ) {
            toast.success(t('auth.alert.email_format_valid'))
            set_is_email_valid(true)
        }else if(email != "") {
            toast.error(t('auth.alert.email_format_invalid'))
            set_is_email_valid(false)
        }
    
    }

    const getExpiredDate=(register_date)=>{
        var register_date_x = new Date(register_date);
        var expired_date = new Date(register_date_x);
        var new_month = (register_date_x.getMonth())+parseInt(12)
        expired_date.setMonth(new_month);
        expired_date = expired_date.getFullYear()+"-"+(expired_date.getMonth()+1)+"-"+expired_date.getDate()
        return expired_date
    }

    const validateRegister=()=>{
        if(!agreement){
            toast.error(t('auth.alert.agree_terms'))
            return
        }
        // else if(serial_number == ""){
        //     toast.error(t('auth.serial_number_required'));
        // }
        else if(password == ""){
            toast.error(t('auth.password_required'));
        }else if(phone == ""){
            toast.error(t('auth.phone_required'));
        }else if(confirm_password == ""){
            toast.error(t('auth.confirm_password_required'));
        }else if(confirm_password != password){
            toast.error(t('auth.password_mismatch'));
        }else{
            registerWarranty();
        }
    }

    const getCurrentDate=(separator='')=>{
  
      let newDate = new Date()
      let date = newDate.getDate();
      let month = newDate.getMonth() + 1;
      let year = newDate.getFullYear();
      
      return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }

    const registerWarranty=async()=>{
        var register_date = localStorage.getItem('register_date')
        var no_wa = "62"+phone.slice(1,999);
        if(is_email_valid){
            set_loading(true)
            var expired_date = getExpiredDate(register_date ? register_date : getCurrentDate('-'))
            global.serial_number = serial_number
            var params = {serial_number:serial_number,phone:no_wa,full_name:full_name,email:email,password:password,register_date:register_date,expired_date:expired_date}
            localStorage.setItem("registerUser", JSON.stringify(params));
            const response = await postData(`${process.env.REACT_APP_API}/register-warranty`,params)
            set_loading(false)
            console.log(response,"response")
            if(response){
                if(response.data.message === 'success'){
                    set_seconds(30)
                    toast.success(t('auth.alert.register_success'))
                    localStorage.removeItem("registerUser")
                    // localStorage.setItem("serial_number",serial_number)
                    navigate('/verification',{ state: {customer_code:response.data.customer_code,full_name:full_name,email:email,serial_number:serial_number}})
                }else if(response.data.message === 'exists'){
                    toast.success(t('auth.alert.account_exists'))
                }else if(response.data.message === 'serial_number_already_exist'){
                    toast.error(t('auth.alert.serial_number_already_exist'))
                }
            }
        }else{
            toast.error(t('auth.alert.email_format_invalid'))
        }
    }

    const agreeToTerms=()=>{
        toast.success(t('auth.alert.terms_agreed'))
        set_agreement(true)
        set_serial_number(searchParams.get("serial_number"))
    }

    return(
        <form style={{height:'100vh'}}>
            <div class="grid grid-cols-2 gap-4 mb-4">
                <div className="py-10 px-10">
                    <header className="pl-10 flex">
                        <img src={process.env.PUBLIC_URL + '/logo192.png'} alt="logo" width={'7%'}/>
                        <h2 className='my-auto ml-5 font-semibold text-2xl color-2'>Vancare App</h2>
                    </header>
                    <div className="mt-10 grid px-8">
                        {
                            type_sn === null ? 
                            
                        <div className="my-2 px-8">
                            <div className='text-center px-5'>
                                <h2 className='color-2'>{t('auth.chose_how_to_register')} {email}</h2>
                            </div>
                            <div className="mt-2 flex justify-center">
                                <button className='btn-register' onClick={(e) => set_type_sn('get_device')}>{'Get Device Serial Number'}</button>
                            </div>
                            <div className="mt-2 flex justify-center">
                                <p className='color-lvl-4'>Or</p>
                            </div>
                            <div className="mt-2 flex justify-center">
                                <button className={'btn-resend'} onClick={(e) => set_type_sn('scan_barcode')}>{'Scan Barcode'}</button>
                            </div>
                        </div> :
                        <>
                            {/* <div className="my-2">
                                <input disabled type="text" name="serial_number" id="serial_number" placeholder='Serial Number' onChange={(e) => set_serial_number(e.target.value)} value={serial_number} className="block w-full input-1"/>
                            </div> */}
                            <div className="my-2">
                                <input type="email" name="email" id="email" placeholder='Email Address' onChange={(e) => set_email(e.target.value)} value={email} className="block w-full input-1"/>
                            </div>
                            <div className="my-2">
                                <input type="text" name="phone" id="phone" placeholder={t('auth.placeholder_phone')} onChange={(e) => set_phone(e.target.value)} value={phone} className="block w-full input-1"/>
                            </div>
                            <div className="my-2">
                                <input type="text" name="full_name" id="full_name" placeholder={t('auth.full_name')} onChange={(e) => set_full_name(e.target.value)} value={full_name} className="block w-full input-1"/>
                            </div>
                            <div className="my-2">
                                <input type="password" name="password" id="password" placeholder='Password' onChange={(e) => set_password(e.target.value)} value={password} className="block w-full input-1"/>
                            </div>
                            <div className="my-2">
                                <input type="password" name="confirm_password" id="confirm_password" placeholder='Confirm Password' onChange={(e) => set_confirm_password(e.target.value)} value={confirm_password} className="block w-full input-1"/>
                            </div>
                            <div className='flex justify-between px-8'>
                                <label onClick={() => navigate('/forgot-password')} className='text-sm text-gray-500 cursor-pointer'>Device Registered?</label>
                            </div>
                            <div className="mt-6 px-8">
                                { loading ? 
                                <div role="status" className="mt-2 flex justify-center">
                                    <svg aria-hidden="true" class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-500" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
                                    </svg>
                                    <span class="sr-only">Loading...</span>
                                </div> : 
                                <div className="mt-2 flex">
                                    <button type='button' className='btn-register' onClick={(e) => validateRegister()}>{t('auth.sign_up')}</button>&nbsp; &nbsp; &nbsp; &nbsp;
                                    <button type='button' className='btn-resend' onClick={(e) => navigate('/login')}>{t('auth.login')}</button>
                                </div>
                                }
                            </div>
                        </>
                        }
                    </div>
                </div>
                <div className="background-page text-left p-5" style={{height:'100vh'}}>
                    { type_sn === 'get_device' && agreement === false &&
                    <div>
                        <h2 className='my-auto text-center font-semibold text-2xl color-2'>{t('auth.terms_and_conditions')}</h2>
                    <h1><b>{t('auth.explanation')}</b></h1>
                    <p>
                        {t('auth.explanation_text')}
                    </p>
                    &nbsp;
                        <ul>
                            <b>
                            {t('auth.reason_of_use')}
                            </b>
                            <li>
                            1. {t('auth.reason_of_use_1')}
                            </li>
                            <li>
                            2. {t('auth.reason_of_use_2')}
                            </li>
                            <li>
                            3. {t('auth.reason_of_use_3')}
                            </li>
                            <li>
                            4. {t('auth.reason_of_use_4')}
                            </li>
                        </ul>
                        &nbsp;
                        <ul><b>{t('auth.how_to_protect')}</b>
                            <li>1. {t('auth.how_to_protect_1')}</li>
                            <li>2. {t('auth.how_to_protect_2')}</li>
                            <li>3. {t('auth.how_to_protect_3')}</li>
                        </ul>
                        &nbsp;
                        <ul><b>{t('auth.agreement')}</b>
                            <p>{t('auth.agreement_text')}</p>

                        <li>1. {t('auth.agreement_1')}</li>
                        <li>2. {t('auth.agreement_2')}</li>
                        </ul>
                        <p>{t('auth.sn_info')}  <a>xxxxxxx@gmail.com</a> {t('auth.or')} +62 xxxxxxxxx.</p>
                        
                        <div className="mt-2 flex">
                            <button type='button' className='btn-register' onClick={(e) => agreeToTerms()}>{t('auth.agree')}</button>&nbsp; &nbsp; &nbsp; &nbsp;
                            <button type='button' className='btn-resend' onClick={(e) => {
                                set_agreement(false)
                                set_type_sn(null)
                            }}>{t('auth.disagree')}</button>
                        </div>
                    </div> }
                </div>
            </div>
        </form>
    );
}